$(function() {

	class Sorter {
		constructor(table) {
			this.$table = $(table);
			this.$list = this.$table.next('.grid-results').find('.grid');

			this.inverse = false;
			this.current = null;

			this.$sorters = this.$table.find('[data-sort]');

			this.defaultIcons();
			this.events();
		}

		static find() {
			$('[data-sorter]').each(function() {
				new Sorter(this);
			});
		}

		defaultIcons() {
			let $span = $('<span class="sorter">\
							<span class="fa-caret-up"></span>\
							<span class="fa-caret-down"></span>\
						</span>');

			this.$sorters.each(function() {
				$(this).prepend($span.clone());
			});
		}

		events() {

			this.$sorters.on('click', ev => {
				let $this = $(ev.currentTarget),
					index = $this.index(),
					type = null,
					checkNumeric = $this.data('type') === 'number';

				this.reset();

				if(this.current === index) {
					this.inverse = !this.inverse;
				}

				this.current = index;
				$this.addClass(this.inverse ? 'fa-caret-up' : 'fa-caret-down');

				this.$list.find('.col').filter(function() {
					return $(this).index() === index;
				}).sortElements((a, b) => {
					a = $.text([a]);
					b = $.text([b]);

					if(checkNumeric) {
						a = +a.replace(/[^\d.]/g, '');
						b = +b.replace(/[^\d.]/g, '');
					}

					return a > b
						? this.inverse ? -1 : 1
						: this.inverse ? 1 : -1;
				}, function() { return this.parentNode; });

			});

		}

		reset() {
			this.$sorters.removeClass('fa-caret-up fa-caret-down');
			return this;
		}
	}

	window.Sorter = Sorter;

	$.fn.sortElements = (function() {
		var sort = [].sort;
		return function(comparator, getSortable) {

			getSortable = getSortable || function(){return this;};

			var placements = this.map(function() {

				var sortElement = getSortable.call(this),
					parentNode = sortElement.parentNode,

					// Since the element itself will change position, we have
					// to have some way of storing it's original position in
					// the DOM. The easiest way is to have a 'flag' node:
					nextSibling = parentNode.insertBefore(
						document.createTextNode(''),
						sortElement.nextSibling
					);

				return function() {

					if (parentNode === this) {
						throw new Error(
							"You can't sort elements if any one is a descendant of another."
						);
					}

					// Insert before flag:
					parentNode.insertBefore(this, nextSibling);
					// Remove flag:
					parentNode.removeChild(nextSibling);

				};

			});

			return sort.call(this, comparator).each(function(i){
				placements[i].call(getSortable.call(this));
			});
		};
	})();

});